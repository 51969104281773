import CTA from '../components/cta';
import Differentials from '../components/differentials';
import Header from '../components/header';
import Layout from '../components/layout';
import Partners from '../components/partners';
import Product from '../components/product';
import React from 'react';

export default function IndexPage() {
  return (
    <Layout title="Sistemas de Gestão Empresarial" transparent>
      <Header />
      <main>
        <Product
          imgName="mr1"
          alt="Imagem MR1"
          title="MR1"
          description="O Sistema de Gestão Empresarial da SysRS."
          list={[
            "Não sei quais recursos adicionar aqui",
            "Ver com o Celso o que vamos colocar",
            "Tem que ser uma lista legal que venda",
            "Uau como o MR1 é legal, muito show",
            "Mais tópicos",
            "Não sei quais recursos adicionar aqui",
            "Ver com o Celso o que vamos colocar",
            "Tem que ser uma lista legal que venda",
            "Uau como o MR1 é legal, muito show",
            "Mais tópicos",
          ]}
          to="/produtos/mr1" />

        <Product
          imgName="mre"
          alt="Imagem MRE"
          title="MRE"
          description="O Sistema de Esquadrias da SysRS."
          list={[
            "Não sei quais recursos adicionar aqui",
            "Ver com o Celso o que vamos colocar",
            "Tem que ser uma lista legal que venda",
            "Uau como o MR1 é legal, muito show",
            "Mais tópicos",
            "Não sei quais recursos adicionar aqui",
            "Ver com o Celso o que vamos colocar",
            "Tem que ser uma lista legal que venda",
            "Uau como o MR1 é legal, muito show",
            "Mais tópicos",
          ]}
          to="/produtos/mre"
          reverse />

        <Product
          imgName="mrmobile"
          alt="Imagem MRMobile"
          title="MRMobile"
          description="O Sistema de Pedidos Mobile da SysRS."
          list={[
            "Realize pedidos até mesmo sem internet.",
            "Catálogos personalizáveis por representante.",
            "Cadastro de novos clientes.",
            "Possibilidade de trabalhar com o conceito de grade.",
            "Diversas imagens para um mesmo produto e cor.",
            "Diversas opções de tabelas de preços.",
            "Região de venda configurável por representante.",
            "Controle de cotas por marcas, segmentos e outros.",
            "Pedidos de pronta-entrega quando estiver online.",
          ]}
          to="/produtos/mrmobile" />

        {/* <Partners /> */}

        <Differentials list={[
          {
            title: 'Integração de sistemas',
            description: 'Sistemas poderosos que funcionam ainda melhores juntos.',
            icon: 'phonelink',
          },
          {
            title: 'Suporte especializado',
            description: 'Remoto ou prestado junto com você quando necessário.',
            icon: 'support_agent',
          },
          {
            title: 'Sem custo de implantação',
            description: 'Pague somente a mensalidade.',
            icon: 'money_off',
          },
          {
            title: 'Modularização',
            description: 'Você contrata somente os módulos que serão necessários.',
            icon: 'auto_awesome_mosaic',
          },
          {
            title: 'Sem custo de implantação',
            description: 'Pague somente a mensalidade.',
            icon: '',
          },
          {
            title: 'Sem custo de implantação',
            description: 'Pague somente a mensalidade.',
            icon: '',
          },
        ]} />

        <CTA />
      </main>
    </Layout>
  )
}
