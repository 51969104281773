import * as s from './styles/header.module.scss';
import React, { ReactElement, useEffect, useState } from 'react';
import { getImage } from 'gatsby-plugin-image';
import { graphql, useStaticQuery } from 'gatsby';
import BackgroundImage from 'gatsby-background-image';
import { convertToBgImage } from 'gbimage-bridge';

const query = graphql`
  {
    file(name: {eq: "background"}) {
      childImageSharp {
        gatsbyImageData
      }
    }
  }
`

export default function Header(): ReactElement {
  const data = useStaticQuery(query)
  const image: any = convertToBgImage(getImage(data.file))
  const backgrounds = [
    'linear-gradient(to right, #2a71bc, #06396f)',
    image.fluid,
  ]

  const [isTransparent, setTransparent] = useState(false)

  useEffect(() => {
    const callback = () => {
      if ((window.scrollY !== 0) !== isTransparent)
        setTransparent(window.scrollY !== 0)
    }
    window.addEventListener('scroll', callback)
    return () => window.removeEventListener('scroll', callback)
  })

  return (
    <>
      <BackgroundImage
        Tag="header"
        className={s.Header}
        fluid={backgrounds}
      >
        <div className={s.Header_box}>
          <div>
            <h1 className="heading u-margin-bottom">
              Gestão empresarial feita para <b>você.</b>
            </h1>
            Sistemas de qualidade para atender as suas necessidades.
          </div>
        </div>
      </BackgroundImage>
      {/* <header className={s.Header}>
      </header> */}
      <div className={`${s.ScrollDown} ${isTransparent ? s.ScrollDown___hidden : ''}`}>
        <h2 className="subheading">Conheça nossos produtos</h2>
        <h2 className={`material-icons subheading ${s.ScrollDown_icon}`}>expand_more</h2>
      </div>
    </>
  )
}
