import * as s from './styles/differentials.module.scss';
import React, { ReactElement } from 'react';

interface Props {
  list: {
    icon: string,
    title: string,
    description: string,
  }[]
}

export default function Differentials({ list }: Props): ReactElement {
  return (
    <section className={s.Differentials}>
      <h1 className="title">Nossos diferenciais</h1>
      <h2 className="subtitle">O que faz a SysRS ser a solução certa para você</h2>
      <ul className={s.Differentials_list}>
        {list.map(({ icon, title, description }, i) => (
          <li key={`diff${i}`}>
            <figure className={s.Differentials_figure}>
              <span className={`material-icons ${s.Differentials_icon}`}>{icon}</span>
              <figcaption className={s.Differentials_figcaption}>
                <h1 className="topic">
                  {title}
                </h1>
                <h2 className="info">
                  {description}
                </h2>
              </figcaption>
            </figure>
          </li>))}
      </ul>
    </section>
  )
}
